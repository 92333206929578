import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import styles from './Article.module.scss';

const Article = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      desktopImage: file(relativePath: { eq: "desktopArticleImage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tabletImage: file(relativePath: { eq: "tabletArticleImage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 766) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileImage: file(relativePath: { eq: "mobileArticleImage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 519) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const articleImageSources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.tabletImage.childImageSharp.fluid,
      media: `(min-width: 766px)`,
    },
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 960px)`,
    },
  ];

  return (
    <BackgroundImage
      id="Article"
      className={styles.wrapper}
      fluid={articleImageSources}
      alt=""
      title=""
    >
      <div className={styles.section}>
        <div className={styles.container}>
          <div className={styles.listContainer}>
            <h2 className={styles.title}>5 boas razões para oferecer livros neste natal</h2>
            <div className={styles.listItem}>
              <h4 className={styles.listItemTitle}>1 - Existe um livro para cada um</h4>
              <span className={styles.listItemParagraph}>
                Os livros vêm em todos os gêneros, estilos e tamanhos de fonte, por isso, alguém que
                "não gostar de livros" ainda não encontrou o certo.
              </span>
            </div>
            <div className={styles.listItem}>
              <h4 className={styles.listItemTitle}>2 - Ler é muito relaxante</h4>
              <span className={styles.listItemParagraph}>
                Ler relaxa mais do que ouvir música, passear ou tomar um chá.
              </span>
            </div>
            <div className={styles.listItem}>
              <h4 className={styles.listItemTitle}>3 - Um bom livro muda mentalidades</h4>
              <span className={styles.listItemParagraph}>
                Cansado de discutir com o seu tio sobre política e afins? Compre-lhe um livro. Um
                livro bem escrito é muito mais convincente do que qualquer discurso.{' '}
              </span>
            </div>
            <div className={styles.listItem}>
              <h4 className={styles.listItemTitle}>
                4 - O cheiro de um livro evoca boas sensações
              </h4>
              <span className={styles.listItemParagraph}>
                Pegar um livro, folheá-lo e depois cheirá-lo: aposto que você já fez isso.
              </span>
            </div>
            <div className={styles.listItem}>
              <h4 className={styles.listItemTitle}>
                5 - Dar um livro é dar a conhecer novos mundos
              </h4>
              <span className={styles.listItemParagraph}>
                Um livro bem escrito transporta-nos para outros mundos idealizados pelos seus
                autores. Oferecer um livro é quase como mandar a pessoa numas férias mentais!
              </span>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default Article;

import React, { useEffect, useState } from 'react';

interface CounterProps {
  classname: string;
  initialValue: number;
  incrementIntervalMs: number;
  incrementValue: number;
  maxValue: number;
}

const Counter = ({
  classname,
  initialValue,
  incrementIntervalMs,
  incrementValue,
  maxValue,
}: CounterProps): React.ReactElement => {
  const [count, setCount] = useState(initialValue);

  useEffect(() => {
    if (count < maxValue) {
      setTimeout(() => {
        setCount(count + incrementValue);
      }, incrementIntervalMs);
    }
  }, [count]);

  return <h3 className={classname}>{count}</h3>;
};

export default Counter;

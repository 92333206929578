import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

//POST NOTES

import postit01 from '../images/postIt01.svg';
import postit02 from '../images/postIt02.svg';
import postit03 from '../images/postIt03.svg';
import postit04 from '../images/postIt04.svg';
import postit05 from '../images/postIt05.svg';
import postit06 from '../images/postIt06.svg';

// MOBILE IMAGES

import gingerman from '../images/gingerman.png';
import candycane from '../images/candyCane.png';

import styles from './PostNotes.module.scss';

const PostNotes = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "book-background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      id="PostNotes"
      className={styles.wrapper}
      fluid={data.file.childImageSharp.fluid}
      alt=""
    >
      <div className={styles.section}>
        <span className={styles.fakeAnchor} id="postNotes-fake-anchor"></span>
        <div className={styles.container}>
          <div className={styles.contentContainer}>
            <div className={styles.sectionHeader}>
              <h2 className={styles.title}>Um livro para todos</h2>
              <span className={styles.subtitle}>
                Um guia, um livro de culinária, uma enciclopédia, uma história para adormecer ou um
                mundo de fadas e feiticeiros... Há um livro para todos.
              </span>
            </div>
            <div className={styles.postNotesContainer}>
              <img className={styles.postNote} src={postit01} alt=""></img>
              <div className={styles.element}>
                <img src={gingerman}></img>
              </div>
              <img className={styles.postNote} src={postit03} alt=""></img>
              <img className={styles.postNote} src={postit02} alt=""></img>
              <img className={styles.postNote} src={postit04} alt=""></img>
              <img className={styles.postNote} src={postit05} alt=""></img>
              <div className={styles.element}>
                <img src={candycane}></img>
              </div>
              <img className={styles.postNote} src={postit06} alt=""></img>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default PostNotes;

import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { FaPaperPlane, FaCheck, FaTimes } from 'react-icons/fa';

import logo from '../images/logo.svg';
import styles from './Footer.module.scss';

const Footer = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "footer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const [inputText, setInputText] = useState<string>('');
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
  const [buttonState, setButtonState] = useState<'active' | 'loading' | 'success'>('active');
  const [helperText, setHelperText] = useState<string>('');

  async function subscribeNewsletter(email: string) {
    const resp = await fetch('/.netlify/functions/subscribe-newsletter', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
      }),
    });
    if (resp.status !== 200) {
      throw new Error('Got response with status code ' + resp.status);
    }
  }

  async function onSubmit() {
    if (!inputText.match(/^\S+@\S+\.\S+$/)) {
      setHelperText('O email que inseriu não é válido');
      return;
    }
    try {
      setButtonState('loading');
      await subscribeNewsletter(inputText);
      setButtonState('success');
      setHelperText('Obrigada pela sua subscrição!');
      setIsInputDisabled(true);
    } catch (error) {
      console.error(error);
      setButtonState('active');
      if (error && error.message === 'Failed to fetch') {
        setHelperText('A rede falhou ao enviar o seu pedido.');
      } else {
        setHelperText('Ocorreu um erro. Tente novamente mais tarde.');
      }
    }
  }

  return (
    <BackgroundImage
      id="footer"
      className={styles.wrapper}
      fluid={data.file.childImageSharp.fluid}
      alt=""
    >
      <footer className={styles.section}>
        <div className={styles.container}>
          <h2 className={styles.signature}>Livros. Presentes para todos.</h2>
          <div className={styles.newsletterWrapper}>
            <span className={styles.newsletterCta}>
              Não perca nenhuma novidade, subscreva-se à nossa Newsletter!
            </span>
            <div className={styles.newsletterContainer}>
              <div className={styles.newsletterBox}>
                <label className={styles.hiddenLabel}>Email</label>
                <input
                  className={styles.input}
                  disabled={isInputDisabled}
                  type="email"
                  name="email"
                  placeholder="name@mailprovider.com"
                  value={inputText}
                  onChange={e => {
                    setInputText(e.target.value);
                    setHelperText('');
                    setButtonState('active');
                  }}
                ></input>
              </div>

              <button
                disabled={inputText === ''}
                className={
                  styles.button +
                  (buttonState === 'active' ? ' ' + styles.active : '') +
                  (buttonState === 'loading' ? ' ' + styles.loading : '') +
                  (buttonState === 'success' ? ' ' + styles.success : '')
                }
                onClick={onSubmit}
              >
                {buttonState === 'active' && <FaPaperPlane></FaPaperPlane>}
                {buttonState === 'loading' && <div className={styles.loader}></div>}
                {buttonState === 'success' && <FaCheck></FaCheck>}
              </button>
            </div>
            <span className={styles.newsletterMessage}>{helperText}</span>
          </div>

          <div className={styles.infoContainer}>
            <div className={styles.logoContainer}>
              <img className={styles.logo} src={logo} alt="Apel Logo" />
            </div>

            <span className={styles.copyright}>
              Powered by{' '}
              <a
                className={styles.link}
                href="https://moscadigital.pt/?utm_source=natal.apel.pt"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mosca Digital
              </a>
            </span>
          </div>
        </div>
      </footer>
    </BackgroundImage>
  );
};

export default Footer;

import React from 'react';

import Footer from './Footer';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps): React.ReactElement => {
  return (
    <div>
      <main>{children}</main>
      <Footer></Footer>
    </div>
  );
};

export default Layout;

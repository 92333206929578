import React, { useEffect, useState } from 'react';
import { useVisible } from 'react-hooks-visible';
import { useCountUp } from 'react-countup';

import styles from './CounterSection.module.scss';
import Counter from './Counter';

const CounterSection = (): React.ReactElement => {
  const [hasCountUpStarted, setHasCountUpStarted] = useState(false);
  const [sectionRef, isSectionVisible] = useVisible<HTMLDivElement, boolean>(vi => vi > 0.2);
  const { countUp: npurchasedBooks, start: startNPurchasedBooksCountUp } = useCountUp({
    startOnMount: false,
    start: 0,
    end: 3000,
    duration: 2,
  });
  const { countUp: nSmiles, start: startnSmilesCountUp } = useCountUp({
    startOnMount: false,
    start: 0,
    end: 6,
    duration: 5,
  });

  useEffect(() => {
    if (isSectionVisible && !hasCountUpStarted) {
      setHasCountUpStarted(true);
      startNPurchasedBooksCountUp();
      startnSmilesCountUp();
    }
  }, [isSectionVisible]);

  return (
    <div ref={sectionRef} id="counterSection" className={styles.section}>
      <div className={styles.container}>
        <h2 className={styles.title}>Desde que entrou nesta página</h2>
        <div className={styles.factsContainer}>
          <div className={styles.factContent}>
            <p className={styles.factDescription}>Livros comprados</p>
            <Counter
              classname={styles.counter}
              initialValue={0}
              incrementValue={1}
              incrementIntervalMs={300}
              maxValue={999}
            ></Counter>
          </div>
          <span className={styles.factDescription}>=</span>
          <div className={styles.factContent}>
            <Counter
              classname={styles.counter}
              initialValue={0}
              incrementValue={3}
              incrementIntervalMs={50}
              maxValue={9999}
            ></Counter>
            <p className={styles.factDescription}>sorrisos alcançados</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterSection;

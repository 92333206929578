import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import BookStoresMap from './BookStoresMap';

import styles from './BookshopLocalize.module.scss';

import postIt from '../images/postItFooter.svg';

const BookshopLocalize = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "mapSectionBackground.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      id="bookshopLocalize"
      className={styles.wrapper}
      fluid={data.file.childImageSharp.fluid}
      alt=""
    >
      <div className={styles.section}>
        <div className={styles.container}>
          <div className={styles.contentContainer}>
            <div className={styles.sectionHeader}>
              <h2 className={styles.title}>Há sempre um livro por perto</h2>
              <span className={styles.subtitle}>
                Veja aqui as livrarias perto de si e faça alguém sorrir, oferecendo um livro neste
                Natal.
              </span>
            </div>
            <div className={styles.mapContainer}>
              <BookStoresMap />
              <div className={styles.postItContainer}>
                <img src={postIt}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default BookshopLocalize;

import React, { useEffect, useRef, useState } from 'react';
import { useJsApiLoader, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { uniqBy } from '../utils/nodash';
import useVisibilitySensor from '@rooks/use-visibility-sensor';

import { FaCrosshairs } from 'react-icons/fa';

import styles from './BookStoresMap.module.scss';

import bookstoreMarkerIcon from '../images/bookstoreMarker.png';

interface Coord {
  lat: number;
  lng: number;
}

interface MapBookStore {
  id: string;
  location: Coord;
  title: string;
}

interface BookStoreResult {
  place_id: string;
  geometry: {
    location: Coord;
  };
  name: string;
}

function getDistanceInM(lat1: number, lon1: number, lat2: number, lon2: number) {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1609.344;
    return dist;
  }
}

const Map = (): React.ReactElement => {
  const googleMapsApiKey = process.env.GATSBY_GOOGLE_MAPS_API_KEY;
  if (!googleMapsApiKey) {
    throw new Error('Missing GATSBY_GOOGLE_MAPS_API_KEY env var');
  }
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey,
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const [openInfoIndex, setOpenInfoIndex] = useState<number | null>(null);

  const dragging = useRef<boolean>(false);
  const lastUpdateCenter = useRef<Coord | null>({
    lat: 38.7215097,
    lng: -9.1396202,
  });
  const lastUpdateZoom = useRef<number | null>(15);

  const [mapBookStores, setMapBookStores] = useState<Array<MapBookStore>>([
    {
      id: 'ChIJH3kt8p8zGQ0RmbqQcwY_Wts',
      location: { lat: 38.732954, lng: -9.144755 },
      title: 'Livraria Almedina',
    },
    {
      id: 'ChIJQ6emxH40GQ0R5YavWc6VioY',
      location: { lat: 38.7099113, lng: -9.141057499999999 },
      title: 'Instituto Macrobiótico de Portugal',
    },
    {
      id: 'ChIJ42oie4IzGQ0RWclulg6ETKA',
      location: { lat: 38.7202462, lng: -9.1449891 },
      title: 'Assouline at Jncquoi',
    },
    {
      id: 'ChIJXUCTIYEzGQ0Rac5bVXQn-hg',
      location: { lat: 38.71602049999999, lng: -9.1413288 },
      title: 'Note! Restauradores',
    },
    {
      id: 'ChIJn0hHFpAzGQ0RlxrrMYRm4lc',
      location: { lat: 38.7250884, lng: -9.133253999999999 },
      title: "It's a Book",
    },
    {
      id: 'ChIJvYtkgoAzGQ0R9qcPGhnrgJY',
      location: { lat: 38.7275794, lng: -9.131713399999999 },
      title: 'XYZ Books',
    },
    {
      id: 'ChIJkZCwyJ0zGQ0R0uZ4e7f1mP4',
      location: { lat: 38.72759779999999, lng: -9.1434821 },
      title: 'Livraria Baptista',
    },
    {
      id: 'ChIJDQdRO5ozGQ0RdCESExXIb5I',
      location: { lat: 38.7284951, lng: -9.1364131 },
      title: 'Tigre de Papel - Livraria',
    },
    {
      id: 'ChIJlzJc2p4zGQ0R2-kZ0dyQTxk',
      location: { lat: 38.728866, lng: -9.1377097 },
      title: 'Leituria',
    },
    {
      id: 'ChIJY2GNr3czGQ0RsL0HrE1XKEA',
      location: { lat: 38.7253667, lng: -9.1481922 },
      title: 'Livraria Canção Nova',
    },
    {
      id: 'ChIJy7k4qHg0GQ0REntirU3_mSI',
      location: { lat: 38.7131941, lng: -9.139713 },
      title: 'LeYa in Rossio',
    },
    {
      id: 'ChIJs_g5fn4zGQ0RG62hetvTxQo',
      location: { lat: 38.7164641, lng: -9.1504519 },
      title: 'Livraria Britânica',
    },
    {
      id: 'ChIJMYH8MJ8zGQ0R-zONQgsh1zo',
      location: { lat: 38.73224099999999, lng: -9.140706 },
      title: 'Espiral',
    },
    {
      id: 'ChIJRVsY13g0GQ0RSjHZe3bYMoQ',
      location: { lat: 38.710976, lng: -9.139526999999998 },
      title: 'Fnac',
    },
    {
      id: 'ChIJFWJBHn80GQ0R-Vq8VD7jt-Q',
      location: { lat: 38.7107381, lng: -9.141772300000001 },
      title: 'Livraria Sá da Costa',
    },
    {
      id: 'ChIJl-2tIH80GQ0R8n_4uOyrVzc',
      location: { lat: 38.7106505, lng: -9.141135499999999 },
      title: 'Bertrand',
    },
    {
      id: 'ChIJixdZVXg0GQ0RLN9DhWi09H0',
      location: { lat: 38.7105848, lng: -9.1373321 },
      title: 'Livraria Marka',
    },
    {
      id: 'ChIJbWM6NXEzGQ0RjbfdUejfCcI',
      location: { lat: 38.7272391, lng: -9.151561899999999 },
      title: 'Lisbon Book Fair',
    },
    {
      id: 'ChIJV9reD3k0GQ0Rly2THNRpu5g',
      location: { lat: 38.710112, lng: -9.137993 },
      title: 'PAULUS Bookstore Lisbon',
    },
    {
      id: 'ChIJ53UH7J,8zGQ0RM1eb3lrhGpA',
      location: { lat: 38.7329252, lng: -9.144656099999999 },
      title: 'Fnac Connect',
    },
  ]);
  const [loadingMarkers, setLoadingMarkers] = useState<boolean>(false);
  const [showFetchError, setShowFetchError] = useState<boolean>(false);

  // Default center is Lisbon
  const [centerCoord, setCenterCoord] = useState<Coord | null>({
    lat: 38.7215097,
    lng: -9.1396202,
  });
  const [centerMapError, setCenterMapError] = useState('');

  const rootNode = useRef(null);
  const { isVisible } = useVisibilitySensor(rootNode, {
    intervalCheck: false,
    scrollCheck: true,
    resizeCheck: true,
    partialVisibility: true,
  });
  const [hasBeenVisible, setHasBeenVisible] = useState(false);

  useEffect(() => {
    if (isVisible && !hasBeenVisible) {
      setHasBeenVisible(true);
    }
  }, [isVisible]);

  async function updateStoresIfNeeded() {
    if (dragging.current || !mapRef.current) {
      return;
    }
    const center = mapRef.current.getCenter();
    const zoom = mapRef.current.getZoom();
    const bounds = mapRef.current.getBounds();
    const centerLat = center.lat();
    const centerLng = center.lng();
    if (!bounds) {
      return;
    }

    const distanceToLastCenter =
      lastUpdateCenter.current &&
      getDistanceInM(
        centerLat,
        centerLng,
        lastUpdateCenter.current.lat,
        lastUpdateCenter.current.lng,
      );
    const diffToLastZoom = lastUpdateZoom.current && Math.abs(zoom - lastUpdateZoom.current);
    const shouldUpdate =
      distanceToLastCenter === null ||
      distanceToLastCenter >= 60 * 2 ** Math.max(18 - zoom, 0) ||
      diffToLastZoom === null ||
      diffToLastZoom >= 1;

    if (!shouldUpdate) {
      return;
    }
    lastUpdateCenter.current = { lat: centerLat, lng: centerLng };
    lastUpdateZoom.current = zoom;

    const radius = 2 ** (25.5 - zoom);
    setShowFetchError(false);
    setLoadingMarkers(true);
    try {
      const resp = await fetch(
        `/.netlify/functions/fetch-book-stores?lat=${centerLat}&lng=${centerLng}&radius=${radius}`,
      );
      const fetchedBookStores = (await resp.json()) as Array<BookStoreResult>;

      setMapBookStores(
        uniqBy(
          [
            ...mapBookStores,
            ...fetchedBookStores.map(bookStore => ({
              id: bookStore.place_id,
              location: bookStore.geometry.location,
              title: bookStore.name,
            })),
          ],
          'id',
        ),
      );
    } catch (err) {
      setShowFetchError(true);
      console.error('Failed to fetch book stores', err);
    } finally {
      setLoadingMarkers(false);
    }
  }

  function onCenterMapClick() {
    setCenterMapError('');
    navigator.geolocation.getCurrentPosition(
      position => {
        const coord = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCenterCoord(coord);
      },
      err => {
        if (err.code === err.PERMISSION_DENIED) {
          setCenterMapError(
            'A permissão de localização foi rejeitada, active nas configurações e volte a tentar.',
          );
        } else {
          console.error(err);
          setCenterMapError('Não foi possível obter a localização, tente novamente.');
        }
      },
    );
  }
  let mainContent;

  if (loadError) {
    mainContent = <div className={styles.errorNotLoadingMap}>Não foi possível carregar o mapa</div>;
  } else if (!isLoaded || !centerCoord || !hasBeenVisible) {
    mainContent = (
      <div className={styles.mapErrorContainer}>
        <div className={styles.loader}>Loading...</div>
      </div>
    );
  } else {
    mainContent = (
      <div className={styles.mapContainer}>
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: `100%` }}
          clickableIcons={false}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            styles: [
              {
                featureType: 'administrative.land_parcel',
                elementType: 'labels',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'poi',
                elementType: 'labels.text',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'road.local',
                elementType: 'labels',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
            ],
          }}
          zoom={15}
          center={centerCoord}
          onLoad={mapInstance => {
            mapRef.current = mapInstance;
          }}
          onClick={() => setOpenInfoIndex(null)}
          onBoundsChanged={updateStoresIfNeeded}
          onDragStart={() => {
            dragging.current = true;
          }}
          onDragEnd={() => {
            dragging.current = false;
          }}
        >
          {mapBookStores.map((store, i) => (
            <Marker
              key={i}
              onClick={() => setOpenInfoIndex(i)}
              position={store.location}
              icon={{
                url: bookstoreMarkerIcon,
                scaledSize: new google.maps.Size(30, 40),
              }}
              title={store.title}
            >
              {openInfoIndex === i && (
                <InfoWindow onCloseClick={() => setOpenInfoIndex(null)}>
                  <div>
                    <div>
                      <strong>{store.title}</strong>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
        {loadingMarkers && (
          <div className={styles.loadingOverlay}>
            <div className={styles.loader}>Loading...</div>
          </div>
        )}
        <div className={styles.fetchErrorContainer + (showFetchError ? '' : ' ' + styles.hidden)}>
          <span>Ocorreu um erro ao obter as livrarias, verifique a sua rede e tente novamente</span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container} ref={rootNode}>
      <button className={styles.button} onClick={onCenterMapClick}>
        <FaCrosshairs className={styles.icon}></FaCrosshairs>
        Centrar na minha localização
      </button>
      <div className={styles.locationError}>{centerMapError}</div>
      {mainContent}
    </div>
  );
};

export default Map;

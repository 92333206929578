import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Img from 'gatsby-image';

import styles from './Hero.module.scss';
import logo from '../images/logo.svg';
import heroImage from '../images/heroImage.jpg';
import ribbon from '../images/ribbon.png';

const Hero = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      heroDesktopImage: file(relativePath: { eq: "desktopImage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroMobileImage: file(relativePath: { eq: "mobileImage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 820) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const heroImageSources = [
    data.heroMobileImage.childImageSharp.fluid,
    {
      ...data.heroDesktopImage.childImageSharp.fluid,
      media: `(min-width: 767px)`,
    },
  ];

  return (
    <div className={styles.section}>
      <div className={styles.leftSide}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={logo} alt="Apel Logo" />
        </div>
        <Img fadeIn={false} fluid={heroImageSources} className={styles.image} alt="" />
        <div className={styles.ribbonContainer}>
          <img src={ribbon} alt="" />
        </div>
      </div>
      <div className={styles.rightSide}>
        <div className={styles.textContainer}>
          <h1 className={styles.title}>Numa livraria encontra os presentes para todos</h1>
          <AnchorLink href="#postNotes-fake-anchor" className={styles.link}>
            <button className={styles.button}>Saber Mais</button>
          </AnchorLink>
        </div>
      </div>
    </div>
  );
};

export default Hero;

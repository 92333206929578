import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import PostNotes from '../components/PostNotes';
import Article from '../components/Article';
import CounterSection from '../components/CounterSection';
import BookshopLocalize from '../components/BookshopLocalize';

import ogImage from '../images/og-image.png';

import '../styles/main.scss';

const IndexPage = (): React.ReactElement => (
  <Layout>
    <SEO
      title="Apel"
      description="Numa livraria encontra os presentes para todos"
      url="https://natal.apel.pt/"
      imageUrl={ogImage}
    />
    <Hero></Hero>
    <PostNotes></PostNotes>
    <Article></Article>
    <CounterSection></CounterSection>
    <BookshopLocalize></BookshopLocalize>
  </Layout>
);

export default IndexPage;
